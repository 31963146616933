@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"








































































































.events-page
  .filter
    .col
      display: flex
      justify-content: center
      flex-wrap: wrap
      .button
        margin: 0 m(1) m(3) m(1)
  .event
    margin-bottom: m(6)
    &:last-child
      margin-bottom: 0
    .info
      padding-left: col-gap('lg')
      .datetime
        margin-top: m(1)
        .date
          +font('medium')
      .speaker
        margin-top: m(1)
        width: 80%
      .button
        margin-top: m(3)
  .emptyresult
    .col
      text-align: center
      hr
        margin: m(3) 0
      *
        +font('medium')
  .spinner
    margin: m(2) 0

+md
  .events-page
    .event
      .info
        padding-left: col-gap('md')

+sm
  .events-page
    .event
      .info
        padding-left: col-gap('sm')

+xs
  .events-page
    .event
      .info
        padding-left: col-gap('xs') / 2
